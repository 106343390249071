import React from "react";
import styled from "styled-components";
import { colors } from "./GlobalStyles";

const StyledOffer = styled.div`
  height: 105vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (max-width: 1279px) {
    height: auto;
    margin: 100px auto 0;
    display: block;
    padding-left: 0;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  padding-left: 70px;
  padding-right: 50px;
  display: grid;
  margin: 0 auto;
  height: 100%;
  min-width: 600px;

  @media (max-width: 1279px) {
    min-width: auto;
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  @media (max-width: 700px) {
    padding: 0 30px;
    max-width: 100%;
    width: auto;
  }
`;

const TextContainer = styled.div`
  justify-self: center;
  align-self: center;
  border-top: 4px solid ${colors.black};
  height: 600px;
  width: 600px;
  display: grid;
  grid-template-columns: 1fr 140px;
  position: relative;
  h3 {
    margin-top: 15px;
  }

  @media (max-width: 1279px) {
    width: 90%;
    margin: 0 auto;
    height: 650px;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    grid-template-columns: 1fr;
    
    h3 {
      font-size: 24px;
    }
  }
`;

const Icon = styled.div`
  margin: 0 auto;
  z-index: 3;
  position: relative;
  img {
    width: 140px;
    height: 140px;
  }

  @media (max-width: 700px) {
    position: absolute;
    right: 0;
    
    img {
      width: 70px;
      height: 70px;
    }
  }
`;

const Image = styled.div`
  position: relative;
  img {
    height: calc(100% - 4px);
    width: 100%;
  }

  @media (max-width: 1279px) {
    width: 100%;
    height: auto;
    margin-top: 20px;
    border-bottom: none;
  }

  @media (max-width: 700px) {
    margin: 30px;
    width: calc(100% - 60px);
    
    img {
      width: 100%;
      margin: 0 auto;
    }
  }
`;

const TextBox = styled.div`
  position: absolute;
  bottom: 0;
  h4,
  p {
    white-space: pre-line;
  }

  @media (max-width: 700px) {
    position: relative;
    width: auto;
    margin-top: 40px;
  ;
  }
`;

const Line = styled.div`
  height: 4px;
  background-color: ${colors.black};
  width: 100%;
  position: relative;
  bottom: 4px;

  @media (max-width: 1279px) {
    display: none;
  }
`;

const Offer = ({id, title, image, icon, text, line }) => {
    return (
        <StyledOffer id={id}>
            <Wrapper>
                <TextContainer>
                    <h3>{title}</h3>
                    <Icon>
                        <img alt={title} src={icon} />
                    </Icon>
                    <TextBox>
                        <p>{text}</p>
                    </TextBox>
                </TextContainer>
            </Wrapper>
            <Image>
                <img
                    alt={title}
                    src={image}
                />
                {line ? <Line /> : ""}
            </Image>
        </StyledOffer>
    );
};

export default Offer;