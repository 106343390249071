import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import SectionOneBig from "../components/SectionOneBig";
import SectionTwo from "../components/SectionTwo";
import styled from "styled-components";
import { Wrapper } from "../components/GlobalStyles";
import Offer from "../components/Offer";
import leafIcon from "../img/leaf.svg";
import pathsIcon from "../img/paths.svg";
import prefabricationIcon from "../img/prefabrication.svg";
import multimediaIcon from "../img/multimedia.svg";
import design from "../img/design.png";
import multimedia from "../img/multimedia.png";
import { Menu } from "../components/Menu/Menu";
import { Navbar } from "../components/Navbar";

const StyledSectionTwo = styled.div`
  display: grid;
  height: 600px;
  width: 100%;
  z-index: 100;
  

  @media (max-width: 1279px) {
    height: auto;
    margin: 100px auto 0;
  }

  @media (max-width: 700px) {
    h2 {
      font-size: 50px;
    }
  }
`;

const ExamplesBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 200px;

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

const Example = styled.div`
  display: grid;
  text-align: center;
  align-items: center;
  position: relative;
  vertical-align: top;

  h4 {
    z-index: 10;
    margin-top: 80px;
  }

  a,
  a:visited {
    text-decoration: none;
    color: #1b1b1d;
    font-size: 24px;
    margin: 0;
    font-weight: 600;
  }

  @media (max-width: 1279px) {
    margin-top: 60px;
  }
`;

const Image = styled.div`
  margin: 0 auto;
  z-index: 3;
  position: relative;
  img {
    width: 140px;
    height: 140px;
  }
`;

const StyledWrapper = styled(Wrapper)`
  margin: 150px auto;
  
  @media (max-width: 700px) {
    margin-bottom: 50px;
  }
`

const OfferPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        PL_extraMile,
        ENG_extraMile,
        PL_extraMileText,
        ENG_extraMileText,
        PL_allYouNeed,
        ENG_allYouNeed,
        PL_allYouNeedText,
        ENG_allYouNeedText,
        PL_ourStandards,
        ENG_ourStandards,
        PL_technologyBIM,
        ENG_technologyBIM,
        PL_sustainableDesign,
        ENG_sustainableDesign,
        PL_prefabrication,
        ENG_prefabrication,
        PL_multimedia,
        ENG_multimedia,
        PL_technologyBIMText,
        ENG_technologyBIMText,
        PL_sustainableDesignText,
        ENG_sustainableDesignText,
        PL_prefabricationText,
        ENG_prefabricationText,
        PL_multimediaText,
        ENG_multimediaText,
        technologyBIMImage,
        sustainableDesignImage,
        prefabricationImage,
        multimediaImage,
        extraMileImage,
        allYouNeedImage,
      },
    },
  } = data || {};

  const texts = {
    PL_extraMile,
    ENG_extraMile,
    PL_extraMileText,
    ENG_extraMileText,
    PL_allYouNeed,
    ENG_allYouNeed,
    PL_allYouNeedText,
    ENG_allYouNeedText,
    PL_ourStandards,
    ENG_ourStandards,
    PL_technologyBIM,
    ENG_technologyBIM,
    PL_sustainableDesign,
    ENG_sustainableDesign,
    PL_prefabrication,
    ENG_prefabrication,
    PL_multimedia,
    ENG_multimedia,
    PL_technologyBIMText,
    ENG_technologyBIMText,
    PL_sustainableDesignText,
    ENG_sustainableDesignText,
    PL_prefabricationText,
    ENG_prefabricationText,
    PL_multimediaText,
    ENG_multimediaText,
  };

  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);

  const translate = (key) => {
    return `${language.toUpperCase()}_${key}`;
  };

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    } else {
      setLanguage("pl");
      localStorage.setItem("language", "pl");
    }
  };
  return (
    <Layout>
      <>
        <Menu
          language={language === "pl" ? "eng" : "pl"}
          handleLanguageClick={handleLanguageClick}
        />
        <Navbar />
        <SectionOneBig
          title={texts[translate("extraMile")]}
          content={texts[translate("extraMileText")]}
          img={
            !!extraMileImage?.childImageSharp
              ? extraMileImage?.childImageSharp.fluid.src
              : extraMileImage
          }
        />
        <SectionTwo
          title={texts[translate("allYouNeed")]}
          content={texts[translate("allYouNeedText")]}
          img={
            !!allYouNeedImage?.childImageSharp
              ? allYouNeedImage?.childImageSharp.fluid.src
              : allYouNeedImage
          }
        />
        <StyledWrapper>
          <StyledSectionTwo>
            <h2>{texts[translate("ourStandards")]}</h2>
            <ExamplesBox>
              <Example>
                <Image>
                  <a href={"#bim"}>
                    <img alt="icon1" src={pathsIcon} />
                  </a>
                </Image>
                <a href={"#bim"}>
                  <h4>{texts[translate("technologyBIM")]}</h4>
                </a>
              </Example>
              <Example>
                <Image>
                  <a href={"#design"}>
                    <img alt="icon2" src={leafIcon} />
                  </a>
                </Image>
                <a href={"#design"}>
                  <h4>{texts[translate("sustainableDesign")]}</h4>
                </a>
              </Example>
              <Example>
                <Image>
                  <a href={"#prefabrykacja"}>
                    <img alt="icon3" src={prefabricationIcon} />
                  </a>
                </Image>
                <a href={"#prefabrykacja"}>
                  <h4>{texts[translate("prefabrication")]}</h4>
                </a>
              </Example>
              <Example>
                <Image>
                  <a href={"#multimedia"}>
                    <img alt="icon3" src={multimediaIcon} />
                  </a>
                </Image>
                <a href={"#multimedia"}>
                  <h4>{texts[translate("multimedia")]}</h4>
                </a>
              </Example>
            </ExamplesBox>
          </StyledSectionTwo>
        </StyledWrapper>
        <Offer
          id={"bim"}
          title={texts[translate("technologyBIM")]}
          image={
            !!technologyBIMImage?.childImageSharp
              ? technologyBIMImage?.childImageSharp.fluid.src
              : technologyBIMImage
          }
          icon={pathsIcon}
          text={texts[translate("technologyBIMText")]}
          line={true}
        />
        <Offer
          id={"design"}
          title={texts[translate("sustainableDesign")]}
          image={
            !!sustainableDesignImage?.childImageSharp
              ? sustainableDesignImage?.childImageSharp.fluid.src
              : sustainableDesignImage
          }
          icon={leafIcon}
          text={texts[translate("sustainableDesignText")]}
          line={true}
        />
        <Offer
          id={"prefabrykacja"}
          title={texts[translate("prefabrication")]}
          image={
            !!prefabricationImage?.childImageSharp
              ? prefabricationImage?.childImageSharp.fluid.src
              : prefabricationImage
          }
          icon={prefabricationIcon}
          text={texts[translate("prefabricationText")]}
          line={true}
        />
        <Offer
          id={"multimedia"}
          title={texts[translate("multimedia")]}
          image={
            !!multimediaImage?.childImageSharp
              ? multimediaImage?.childImageSharp.fluid.src
              : multimediaImage
          }
          icon={multimediaIcon}
          text={texts[translate("multimediaText")]}
          line={true}
        />
      </>
    </Layout>
  );
};

export default OfferPage;

export const offerPageQuery = graphql`
  query OfferPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        PL_extraMile
        ENG_extraMile
        PL_extraMileText
        ENG_extraMileText
        PL_allYouNeed
        ENG_allYouNeed
        PL_allYouNeedText
        ENG_allYouNeedText
        PL_ourStandards
        ENG_ourStandards
        PL_technologyBIM
        ENG_technologyBIM
        PL_sustainableDesign
        ENG_sustainableDesign
        PL_prefabrication
        ENG_prefabrication
        PL_multimedia
        ENG_multimedia
        PL_technologyBIMText
        ENG_technologyBIMText
        PL_sustainableDesignText
        ENG_sustainableDesignText
        PL_prefabricationText
        ENG_prefabricationText
        PL_multimediaText
        ENG_multimediaText
        technologyBIMImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sustainableDesignImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        prefabricationImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        multimediaImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extraMileImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allYouNeedImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
